if (document.querySelector(".social-share")) {
  // Function to share on Facebook
  const shareOnFacebook = () => {
    const url = window.location.href;
    const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}`;
    window.open(facebookShareURL, "_blank");
  };

  // Function to share on Pinterest
  const shareOnPinterest = () => {
    const url = window.location.href;
    const pinterestShareURL = `https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(
      url
    )}`;
    window.open(pinterestShareURL, "_blank");
  };

  // Function to copy the current page's URL to the clipboard and show a tooltip
  const copyLinkToClipboard = () => {
    const url = window.location.href;
    const tempInput = document.createElement("input");
    tempInput.value = url;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    // Show the tooltip
    const tooltips = document.querySelectorAll(".tooltip");
    tooltips.forEach((tooltip) => {
      tooltip.style.display = "block";

      // Hide the tooltip after a few seconds (e.g., 2 seconds)
      setTimeout(() => {
        tooltip.style.display = "none";
      }, 2000);
    });
  };

  // Attach event listeners to buttons
  const shareButtons = document.querySelectorAll(".share-button");
  const copyLinkButton = document.querySelectorAll(".copy-link-button");

  shareButtons.forEach((button) => {
    button.addEventListener("click", () => {
      const action = button.getAttribute("data-action");
      if (action === "facebook") {
        shareOnFacebook();
      } else if (action === "pinterest") {
        shareOnPinterest();
      }
    });
  });
  copyLinkButton.forEach((copyLink) => {
    copyLink.addEventListener("click", copyLinkToClipboard);
  });
}
