import gsap from "gsap";
import _ from "lodash";
import $ from "jquery";

let $container = $("#page-foliage, .block-foliage");
let $foliage = $(".foliage");

let foliageBranches = [];
let foliageWw = window.innerWidth;
let foliageWhHalf = window.innerHeight / 2;
let foliageScale = _.clamp((foliageWw / 1440) * 0.5, 0, 0.5);
let raf = 0;

const fgMeasure = () => {
  $container = $("#page-foliage, .block-foliage");
  $foliage = $(".foliage");

  foliageWhHalf = window.innerHeight / 2;
  foliageWw = window.innerWidth;
  foliageScale = _.clamp((foliageWw / 1440) * 0.45, 0.2, 0.5);

  const docHeight = Math.max(
    document.documentElement.scrollHeight,
    document.body.scrollHeight
  );

  foliageBranches = _.map($foliage, ($el, i) => {
    const sectionId = $($el).data("section");
    const section = document.getElementById(sectionId) || false;
    const position = $($el).data("yp");

    let yp = docHeight * position;
    if (section) {
      const { y, height } = section.getBoundingClientRect();
      yp = y + height * position;
    }

    gsap.set($el, {
      scale: foliageScale,
    });

    const model = {
      element: $el,
      section,
      position,
      yp,
    };

    return model;
  });
};

const fgUpdate = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;

  _.each(foliageBranches, (branch, i) => {
    const { element, yp } = branch;
    const eHeight = $(element).height();
    const yOffset = (eHeight * foliageScale) / 2;
    const yStart = yp - scrollTop - yOffset;
    const yDiff = scrollTop - yStart - foliageWhHalf;
    const y = yStart + yDiff * -0.1;
    gsap.set(element, { y });
  });
};

const fgResize = () => {
  fgMeasure();
  fgUpdate();
};

const fgStart = () => {
  window.addEventListener("resize", _.debounce(fgResize, 100));
  window.addEventListener("scroll", fgUpdate);
  fgMeasure();

  gsap.set($foliage, {
    opacity: 1,
  });

  _.delay(() => {
    gsap.set($foliage, { opacity: 1 });
    $container.addClass("show");
  }, 1000);

  fgUpdate();
};

fgStart();
