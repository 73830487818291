export default class SiteButton {

	init() {
		
		const keyDown = (e) => {
			if (e.keyCode === 9) {
				document.querySelector('html').classList.add('enable-focus-outlines');
				window.removeEventListener('keydown', keydown);
			}
		};
		
		window.addEventListener('keydown', keyDown);

	}

}
