import $ from "jquery";

import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

import SiteController from "./site/site-controller.js";

import SiteButton from "./site/site-button.js";
import SiteAni from "./site/site-animations.js";

import "./content/carousel";
import "./site/site-header";
import "./content/social-share";
import "./content/youtube-video-embed";
import "./content/load-more-blogs";
import "./content/a11y-dialog";
import "./content/products-filters";
// import "./content/tabs";

const modules = {
    "site-button": SiteButton,
    "site-ani": SiteAni,
};

const siteController = new SiteController(modules);
siteController.init();

window.dispatchEvent(new CustomEvent("site-ani-find"));

gsap.registerPlugin(ScrollTrigger);
