/**
 * Responsive Header with search bar show and hide
 **/

const header = document.getElementById("header");
const mobileMenuToggle = document.querySelector(".mobile-menu-toggle");
const navMenu = document.querySelector(".header__navigation");
const headerSearch = document.querySelector(".header__search");
const searchIcon = document.getElementById("searchIcon");
const closeIcon = document.getElementById("closeIcon");
const searchForm = document.getElementById("searchform");

mobileMenuToggle.addEventListener("click", () => {
  header.classList.toggle("open");
  navMenu.classList.toggle("open");
  mobileMenuToggle.classList.toggle("mobile-menu-open");
});

window.addEventListener("scroll", () => {
  if (window.scrollY > window.innerHeight) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
});

// Show search form on search icon click
searchIcon.addEventListener("click", () => {
  header.classList.add("open");
  headerSearch.classList.add("open");
  searchForm.classList.add("open");
  closeIcon.style.display = "block";
  searchIcon.style.display = "none";
});

// Hide search form on close icon click
closeIcon.addEventListener("click", () => {
  header.classList.remove("open");
  headerSearch.classList.remove("open");
  searchForm.classList.remove("open");
  closeIcon.style.display = "none";
  searchIcon.style.display = "block";
});
