/**
 * Gets the video ID from the URL added by the user and creates the iframe.
 */

document.addEventListener("DOMContentLoaded", function () {
  let youtubeContainer = document.getElementById("video-container");
  if (youtubeContainer) {
    youtubeUrl = youtubeContainer.getAttribute("data-youtube");

    if (youtubeContainer) {
      const embedVideo = () => {
        const videoId = extractVideoId(youtubeUrl);

        if (videoId) {
          const embedCode = `<iframe width="560" height="315" src="https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&loop=1&mute=1&playlist=${videoId}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen" frameborder="0" allowfullscreen></iframe>`;
          document.getElementById("video-container").innerHTML = embedCode;
        } else {
          console.log("Invalid YouTube URL. Please enter a valid YouTube video URL.");
        }
      };

      // Execute embedVideo when the window has finished loading
      window.onload = embedVideo;
    }
  }

  const openButton = document.getElementById("openPopup");
  const closeButton = document.getElementById("closePopup");
  const videoPopup = document.getElementById("videoPopup");
  const header = document.getElementById("header");
  let youtubeVideoContainer;

  // Check if openButton exists
  if (openButton) {
    youtubePopupUrl = videoPopup.getAttribute("data-youtube-popup");
    openButton.addEventListener("click", function () {
      // Show the videoPopup
      const videoIdPopup = extractVideoId(youtubePopupUrl);
      if (!youtubeVideoContainer) {
        if (videoIdPopup) {
          youtubeVideoContainer = document.createElement("div");
          youtubeVideoContainer.id = "popupVideoContainer";
          const youtubeVideo = document.createElement("iframe");
          youtubeVideo.width = "560";
          youtubeVideo.height = "315";
          youtubeVideo.src = `https://www.youtube.com/embed/${videoIdPopup}?autoplay=1&rel=0`;
          youtubeVideo.frameBorder = "0";
          youtubeVideo.allowFullscreen = true;
          youtubeVideo.allow = "autoplay;";
          youtubeVideoContainer.appendChild(youtubeVideo);
          videoPopup.appendChild(youtubeVideoContainer);
          videoPopup.style.display = "flex";
          header.style.display = "none";
          // Hide the page scrollbar
          document.body.style.overflow = "hidden";
        } else {
          console.log("Invalid YouTube URL. Please enter a valid YouTube video URL.");
        }
      }
    });
  }

  // Check if closeButton exists
  if (closeButton) {
    closeButton.addEventListener("click", function () {
      videoPopup.style.display = "none";
      header.style.display = "flex";
      if (youtubeVideoContainer && youtubeVideoContainer.parentNode) {
        youtubeVideoContainer.parentNode.removeChild(youtubeVideoContainer);
        youtubeVideoContainer = null;
      }
      document.body.style.overflow = "auto";
    });
  }

  // Close the popup when clicking outside the video
  window.addEventListener("click", function (event) {
    if (event.target === videoPopup) {
      videoPopup.style.display = "none";
      header.style.display = "flex";
      if (youtubeVideoContainer && youtubeVideoContainer.parentNode) {
        youtubeVideoContainer.parentNode.removeChild(youtubeVideoContainer);
        youtubeVideoContainer = null;
      }
      document.body.style.overflow = "auto";
    }
  });
});

const extractVideoId = (url) => {
  // Regular expression to match YouTube video IDs in various URL formats
  const regExp =
    /(?:\?v=|\/embed\/|\/watch\?v=|\/v\/|\/vi\/|\/e\/|youtu.be\/|\/embed\/)([a-zA-Z0-9_\-]+)/;
  const match = url.match(regExp);

  if (match && match[1]) {
    return match[1];
  } else {
    return null;
  }
};
