import A11yDialog from "a11y-dialog";
import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
    if (document.getElementById("product-dialog-filter") !== null) {
        const container = document.getElementById("product-dialog-filter")
        const dialog = new A11yDialog(container)
        dialog.on('show', function (event) {
            const container = event.target
            const target = event.detail.target
            const opener = target.closest('[data-a11y-dialog-show]')
        })
    }
});