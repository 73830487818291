export default class SiteController {
  constructor(modules) {
    this.modules = modules;
  }

  init(parent = "html") {
    const nodes = document
      .querySelector(parent)
      .querySelectorAll("[data-controller]");

    nodes.forEach((node) => {
      const controllers = node.getAttribute("data-controller").split(",");

      controllers.forEach((controller) => {
        controller = controller.trim();
        if (!node[controller]) {
          try {
            if ( !this.modules[controller] ) {
              return;
            }
            node[controller] = new this.modules[controller](node);
            node[controller].node = node;
            node[controller].init();
          } catch (e) {
            console.log(e);
          }
        }
      });
    });
  }
}
