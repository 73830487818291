import gsap from "gsap";
import _ from "lodash";

const historyElement = document.getElementById("history-timeline");
if (document.contains(historyElement)) {
  const plants = document.getElementsByClassName("tl-plant");
  const iconsParent = document.getElementById("tl-icons-line");
  const iconsDarkLine = document.getElementById("tl-icons-darkline");
  const entries = historyElement.getElementsByClassName("tl-entry");
  const years = [];
  let iconLineHeight = 0;
  let raf = 0;

  for (let entry of entries) {
    if (!entry.classList.contains("spacer")) {
      const entryId = entry.id;
      const iconId = entry.id.replace("entry", "icon");
      const icon = document.getElementById(iconId);
      if (icon) years.push({ entry, icon });
    }
  }

  const positionIcons = () => {
    const iconsOffset = iconsParent.offsetTop;
    let y = 0;
    years.forEach((year) => {
      year.entry.scrollTop;
      const rect = year.entry.getBoundingClientRect();
      y = year.entry.offsetTop - iconsOffset;
      gsap.set(year.icon, { y });
      year.icon.classList.add("show");
    });

    gsap.to(iconsParent, { height: y, duration: 2, delay: 0.35 });
    iconLineHeight = y;
  };

  positionIcons();
  window.addEventListener("resize", positionIcons);

  const update = () => {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    gsap.to(plants, {
      y: scrollTop * 0.3,
      duration: 0.1,
    });

    const { innerHeight: wh } = window;
    const { y, height } = iconsParent.getBoundingClientRect();

    let darkLineHeight = _.min([wh * 0.8 - y, iconLineHeight, scrollTop * 2]);
    gsap.to(iconsDarkLine, { height: darkLineHeight });

    years.forEach((year) => {
      const yearY = gsap.getProperty(year.icon, "y");
      const showYear = darkLineHeight + 10 > yearY;

      if (showYear) year.icon.classList.add("dark");
      else year.icon.classList.remove("dark");

      if (showYear) year.entry.classList.add("show");
      else year.entry.classList.remove("show");
    });

    raf = requestAnimationFrame(update);
  };

  update();
}
