import Splide from '@splidejs/splide';
import '@splidejs/splide/css/core';

document.addEventListener('DOMContentLoaded', function () {
  if (document.getElementById('splide-d9-carousel-range') !== null) {
    new Splide('#splide-d9-carousel-range', {
      perMove: 1,
      gap: 32,
      pagination: false,
      breakpoints: {
        640: {
          gap: 15,
        },
      },
    }).mount();
  }
  if (document.getElementById('splide-d9-carousel-wines') !== null) {
    new Splide('#splide-d9-carousel-wines', {
      perMove: 1,
      gap: 32,
      pagination: false,
      breakpoints: {
        640: {
          gap: 15,
        },
      },
    }).mount();
  }

  if (document.getElementById('d15-carousel-insta-feed') !== null) {
    new Splide('#d15-carousel-insta-feed', {
      perPage: 3,
      perMove: 1,
      gap: 32,
      width: '100%',
      pagination: false,
      breakpoints: {
        640: {
          width: '75%',
          type: 'slide',
          gap: 15,
          perPage: 1,
          pagination: false,
        },
      },
    }).mount();
  }
  if (document.getElementById('splide-d7-carousel') !== null) {
    new Splide('#splide-d7-carousel', {
      perPage: 4,
      perMove: 1,
      gap: 32,
      pagination: false,
      breakpoints: {
        640: {
          gap: 15,
          perPage: 2,
        },
      },
    }).mount();
  }
  if (document.getElementById('d14-carousel-region-carousel') !== null) {
    new Splide('#d14-carousel-region-carousel', {
      type: 'loop',
      gap: 32,
      pagination: false,
      breakpoints: {
        640: {
          gap: 15,
        },
      },
    }).mount();
  }
  if (document.getElementById('a10-full-bleed-image-carousel') !== null) {
    new Splide('#a10-full-bleed-image-carousel', {
      type: 'loop',
      gap: 0,
      pagination: false,
    }).mount();
  }
});
