import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
  let $blogLists = $("#blog_lists");
  let currentPage = 1;

  if (window.location.pathname.includes("blog")) {
    $blogLists.data("category_to_limit_to", "");
  }

  if (window.location.pathname.includes("media")) {
    $blogLists.data("category_to_exclude", "");
  }

  const onLoadMoreClick = (event) => {
    let category_to_limit_to = $blogLists.data("category_to_limit_to");
    let category_to_exclude = $blogLists.data("category_to_exclude");

    let mode = $(this).data("mode");

    if (mode == "e1") {
      $(".item-win-e1").map(function () {
        if ($(this).hasClass("hidden")) {
          $(this).removeClass("hidden");
        }
      });

      $("#load-more-blogs").fadeOut();
      return;
    }

    currentPage++;
    $.ajax({
      type: "POST",
      async: false,
      url: "/wp-admin/admin-ajax.php",
      data: {
        action: "load_more_blogs",
        paged: currentPage,
        cat: $("#cat_selected").val(),
        posts_per_page: $("#posts_per_page").val(),
        mode: mode,
        keywords: $("#s").val(),
        category_to_limit_to: category_to_limit_to,
        category_to_exclude: category_to_exclude,
      },
      beforeSend: function () {
        //
      },
      success: function (response) {
        if (currentPage >= parseInt($("#total_pages").val())) {
          $("#load-more-blogs").fadeOut();
        }

        if (response) {
          $("#blogs-list").append(response);
        }
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("The following error occured: " + textStatus, errorThrown);
      },
    });
  };

  if (document.getElementById("load-more-blogs") !== null) {
    $("#load-more-blogs").on("click", onLoadMoreClick);
  }

  // Load the filtered blogs via AJAX
  function loadFilteredBlogs(categories) {
    $blogLists = $("#blog_lists");
    let category_to_limit_to = $blogLists.data("category_to_limit_to");
    let category_to_exclude = $blogLists.data("category_to_exclude");

    $.ajax({
      type: "POST",
      url: "/wp-admin/admin-ajax.php",
      data: {
        action: "filter_blogs_by_category",
        cat: categories,
        posts_per_page: $("#posts_per_page").val(),
        category_to_limit_to: category_to_limit_to,
        category_to_exclude: category_to_exclude,
      },
      beforeSend: function () {
        // Optional: Add loading indicator
      },
      success: function (response) {
        $("#blog_lists").html(response);
        $("#load-more-blogs").on("click", onLoadMoreClick);
      },
      error: function (jqXHR, textStatus, errorThrown) {
        console.log("Error: " + textStatus, errorThrown);
      },
    });
  }

  if (document.getElementsByClassName("item-cate-checkbox") !== null) {
    $(".item-cate-checkbox").on("change", function (e) {
      e.preventDefault();

      let checked = [];
      $(".item-cate-checkbox").map(function () {
        if (this.checked) {
          checked.push(this.value);
        }
      });

      $blogLists.data("category_to_limit_to", checked);
      loadFilteredBlogs(checked.join(","));
    });

    $("#remove_all_filters").on("click", function () {
      $(".item-cate-checkbox").prop("checked", false);
      loadFilteredBlogs("");
    });
  }
});
