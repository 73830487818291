import gsap from "gsap";
import _ from "lodash";
import $ from "jquery";

const hpChevronElement = document.getElementById("homepage-chevron");
const hpBottleElement = document.getElementById("hp-wine-bottle");
const hpVideoContainerElement = $(".video_container");
const $videoElement = $(".video_container > video");
const $chevronElement = $("#hp-chevron");
const $headerLogo = $(".header__logo");

hpVideoContainerElement.ready(() => {
  gsap.to($chevronElement, {
    opacity: 1,
    duration: 1,
  });
  gsap.to(hpVideoContainerElement, {
    opacity: 1,
    duration: 1,
    delay: 0.65,
  });
});

if (document.contains(hpChevronElement)) {
  let raf = 0;

  const update = () => {
    const ww = window.innerWidth;
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;

    // gsap.to($chevronElement, {
    //   y: -scrollTop * 0.9,
    //   duration: 0.05,
    // });

    // gsap.to($videoElement, {
    //   y: -scrollTop * 0.5,
    //   duration: 0.05,
    // });

    // const bottleParallaxRate = Math.min(ww / 1000, 1);
    // gsap.to(hpBottleElement, {
    //   y: -scrollTop * 0.5 * bottleParallaxRate,
    //   duration: 0.05,
    // });

    if (ww < 600 && scrollTop < 100) {
      $headerLogo.addClass("light");
    } else if ($headerLogo.hasClass("light")) {
      $headerLogo.removeClass("light");
    }

    raf = requestAnimationFrame(update);
  };

  update();
}
