import $ from "jquery";

document.addEventListener("DOMContentLoaded", function () {
    function getFlagSearch(_search) {
        let flag = '&'
        if (_search === '' || _search === '?') {
            flag = '?';
        }

        return flag;
    }

    function getUnderlineSelect() {
        return $('#underline_select').val();
    }

    function getSearchParams() {
        let varieties = [], ranges = [], regions = [], _search = '';
        $(".item-varieties-checkbox").map(function () {
            if (this.checked) {
                varieties.push(this.value)
            }
        });
        $(".item-ranges-checkbox").map(function () {
            if (this.checked) {
                ranges.push(this.value)
            }
        });
        $(".item-regions-checkbox").map(function () {
            if (this.checked) {
                regions.push(this.value)
            }
        });
        if (varieties.length) {
            _search += getFlagSearch(_search) + 'varieties=' + varieties
        }
        if (ranges.length) {
            _search += getFlagSearch(_search) + 'ranges=' + ranges
        }
        if (regions.length) {
            _search += getFlagSearch(_search) + 'regions=' + regions
        }

        if (_search === '?') {
            _search = '';
        }
        return _search;
    }

    function getParamFilters() {
        let filters = [];
        $(".item-filter-types").map(function () {
            if ($(this).hasClass('active')) {
                filters.push($(this).data('value'));
            }
        });

        return filters;
    }

    if (document.getElementById("underline_select") !== null) {
        $('#underline_select').change(function () {
            goToSearch()
        })
    }
    if (document.getElementsByClassName("item-filter-types") !== null) {
        $('.item-filter-types').click(function () {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active bg-white rounded-sm')
            } else {
                $(this).addClass('active bg-white rounded-sm')
            }
            goToSearch()
        });
    }

    if (document.getElementById("products_clear_filters") !== null) {
        $('#products_clear_filters').click(function () {
            const pathname = window.location.pathname
            window.location.href = pathname
        })
    }

    if (document.getElementById("products_apply_filters") !== null) {
        $('#products_apply_filters').click(function () {
            goToSearch()
        })
    }

    function goToSearch() {
        let path_search = '?';
        let _search = getSearchParams()
        let _orderBy = getUnderlineSelect()
        let _filters = getParamFilters();

        if (_search.length == 0 && _orderBy.length == 0 && _filters.length == 0) {
            return false;
        }
        if (_orderBy && _orderBy != '') {
            _orderBy = getFlagSearch(_search) + 'orderBy=' + _orderBy;
        }
        if (_filters.length) {
            let __flag = getFlagSearch(_search);
            if (path_search != '?') {
                __flag = '&'
            }
            _filters = __flag + 'types=' + _filters;
        }

        path_search = _search + _orderBy + _filters
        const pathname = window.location.pathname
        window.location.href = pathname + path_search
    }
})
