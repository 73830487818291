export default class SiteAni {
  init() {
    window.addEventListener("scroll", () => {
      this.findAni();
    });

    window.addEventListener("site-ani-find", () => {
      this.findAni();
    });
  }

  findAni() {
    this.node.querySelectorAll(".site-ani-group").forEach((group) => {
      var delay = 0;
      group
        .querySelectorAll(".site-ani-auto:not(.state-ani-go)")
        .forEach((target) => {
          target.style.transitionDelay = `${delay}ms`;
          delay += 200;
        });

      if (this.inViewport(group)) {
        group.querySelectorAll(".site-ani-auto").forEach((target) => {
          target.classList.add("state-ani-go");
        });
      }
    });

    this.node.querySelectorAll(".site-ani-auto").forEach((target) => {
      if (this.inViewport(target)) {
        target.classList.add("state-ani-go");
      }
    });
  }

  inViewport(element) {
    const box = element.getBoundingClientRect();
    return box.top < window.innerHeight && box.bottom >= 0;
  }
}
